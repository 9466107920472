<script>
import DeviceManage from '@/views/srds/device/DeviceManage'
import Device from '@/views/swfm/device/DeviceDetail'

export default {
  name: 'SWFMDeviceManage',
  extends: DeviceManage,
  components: {
    Device,
  },    
}
</script>