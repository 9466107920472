<template>
    <div>
        <CCard>
        <CCardBody>
            <div class= "row" id="deviceContainer">
            <div class="col-sm-7 col-lg-7">
                <div id="custCarousel" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000" align="center" style="border:1px solid #eee; border-radius:1%; padding:2% 3% 0% 2%;height:100%;margin-right:1%;">
                <!-- 메인 슬라이드 이미지 slides -->
                <div class="carousel-inner">
                    <div class="carousel-item" v-for="(img, index) in deviceImages" :key="index" :class="{ 'active': index === 0 }">
                    <img :src="img.url" :alt="img.label"  style="max-height: 400px;cursor: pointer" @click="openUploadDialog()">
                    <div class="row" id="main-img-caption">
                        <div class="col-sm-12">
                            <svg xmlns="http://www.w3.org/2000/svg" height="22" width="16" viewBox="0 0 512 500"><path fill="#fcfcfc" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                            <br>
                            <span> {{img.created_at}} <br> {{ img.created_at_time}}</span>                  
                        </div>
                    </div>
                    </div>
                </div>
                <!-- 화살표 indicator -->
                <a class="carousel-control-prev" href="#custCarousel" data-slide="prev">
                    <!-- <i class="fa fa-chevron-left" aria-hidden="true" style="color:#848484;font-size:xx-large;font-weight:800"></i> -->
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="32" viewBox="0 0 320 512"><path fill="#848484" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                </a>
                <a class="carousel-control-next" href="#custCarousel" data-slide="next">
                    <!-- <i class="fa fa-chevron-right" aria-hidden="true" style="color:#848484;font-size:xx-large;;font-weight:800"></i> -->
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="32" viewBox="0 0 320 512"><path fill="#848484" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                </a>
                <!-- 썸네일 Thumnails -->
                <div class="carousel-indicators-container">
                    <div class="carousel-indicators-wrapper">
                    <ol class="carousel-indicators list-inline">  <!--  hj :: for spacing add "me-4" at the end of the class -->
                        <li class="list-inline-item" v-for="(img, index) in deviceImages" :key="index" :class="{ 'active': index === 0 }">
                        <div class="image-container">
                            <a :id="'carousel-selector-' + index" :data-slide-to="index" data-target="#custCarousel"> <span id="thumb_caption">{{ img.created_at_thumb }} </span> 
                            <img :src="img.url" class="img-fluid" id="img-thumbnails"> 
                            </a>
                        </div> 
                        </li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-sm-5 col-lg-5">
                <div class="row">
                <!--제품정보 table - 제품번호/설치정보/업로드주기-->
                <div class="col-sm-12 col-lg-12" style="border:1px solid #eee;border-radius:1%; right:2.5%;padding:3% 6% 1% 6%">   
                    <table class="table" v-bind="getDeviceData()">
                    <tbody>
                        <tr style="margin-left:auto;margin-right:auto;">
                        <th style="border-top:none;text-align: center;" colspan="2">
                            <span style="color:#00A8FF;font-size:1.4em;">{{ site.name }} / {{ device.name }}</span>
                        </th>
                        </tr>
                        <tr>
                        <th style="border-top:none" scope="col">제품번호</th>
                        <td style="border-top:none" scope="col">{{deviceGuid}}</td>
                        </tr>
                        <!--<tr>
                        <th scope="col">설치일시</th>
                        <td scope="col">{{installationDate}}</td>
                        </tr>-->
                        <tr>
                        <th scope="col">설치주소</th>
                        <td scope="col">{{deviceAddr}}</td>
                        </tr>
                        <tr>
                        <th scope="col">업로드 주기</th>
                        <td scope="col">{{cronStringData}}</td>
                        </tr>
                        <tr>
                        <th scope="col">최근 측정 시간</th>
                        <td scope="col">{{ sensorUpdated }}  {{ sensorUpdatedTime }}</td>
                        </tr>
                        <tr>
                        <th scope="col"> 센서정보관리</th>
                        <td scope="col">
                            <CButton color="warning" class="float-left mr-1" @click="openDialog('sensor')">
                            <!-- <font-awesome-icon icon="fa-solid fa-gear" /> 수정 -->
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><path fill="#808080" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg> 수정
                            </CButton>
                            <CButton color="primary" class="float-left mr-1" @click="pictureDialog()">
                            <!-- <i class="fa fa-camera"></i> 촬영  -->
                            <!-- <font-awesome-icon icon="fa-solid fa-camera" /> 촬영 -->
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 130 512 312"><path fill="#fcfcfc" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg> 촬영
                            </CButton>   
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <!-- 위험감지 -->
                <div class="col-sm-12" style="border:1px solid #eee;border-radius:5px; right:2.5%; padding: 2.5%; margin:1% 0% 1% 0%;text-align: center;"> 
                    <div class="row" style="padding: 0% 2% 0% 2%;align-items:center;">
                    <!-- box - 1 아이콘 -->        
                    <div class="col-sm-3" style="padding-right:3%;">
                        <div v-html="dangerDetectionImg"></div>
                    </div>
                    <!-- box - 2 내용 -->
                    <div class="col-sm-6">
                        <div v-html="dangerDetectionData()" style="font-size:larger;" ></div>
                    </div>
                    <!-- box - 3 버튼 -->
                    <div class="col-sm-3">
                        <CButton style="margin-top:1.8%;font-weight: 800;" color="info" class="float-right mr-1" id="float-btn" v-show="service_type == 'swfm'" @click="openDialog('incline')">기준치 설정</CButton>
                        <CButton style="background-color:#eee;margin-top:1.8%;font-weight: 800;" class="float-right mr-1" id="float-btn" v-show="service_type == 'swfm'" @click="openDialog('swfm')">가중치 설정</CButton>
                    </div>
                    </div>
                </div>  
                <!-- 배터리 -->  
                <div class="col-sm-12" style="border:1px solid #eee;border-radius:5px; right:2.5%; padding: 2.5%; margin:1% 0% 1% 0%;text-align: center;"> 
                    <div class="row" style="padding: 0% 2% 0% 2%; align-items:center;">
                    <!-- box - 1 아이콘 -->        
                    <div class="col-sm-3">
                        <!-- <i :class="batteryIconClass" :style="{ color:batteryIconColor }" style="font-size:xxx-large"> </i> -->
                        <div v-html="findBatteryImg"></div>
                    </div>
                    <!-- box - 2 내용 -->
                    <div class="col-sm-6">
                        <span style="font-size:larger"> 배터리 잔량  {{ getBatteryDataValue() }} </span>             
                    </div>
                    <!-- box - 3 버튼 -->
                    <div class="col-sm-3">
                        <CButton color="success" class="float-right mr-1" id="float-btn-battery" @click="openDialog('battery')" >배터리 기준 설정</CButton>
                    </div>
                    </div>
                </div>
                </div>
            </div>  
            </div>
        </CCardBody>
        </CCard>
        <Confirm
            ref="confirmDialog"
            title="확인"
            @hide="hideModal"
            color="warning"
        />
        <DeviceInfo
            ref="deviceInfo"
            :isAdmin="is_admin"
            :isMobile="is_mobile"
            :device.sync="device"
            @hide="hideModal"
            @update="onUpdated"
        />
        <SWFMThreshold
            ref="swfmthresholdDialog"
            :isAdmin="is_admin"
            :isMobile="is_mobile"
            :device.sync="device"
            @update="onUpdated"
        />
        <Threshold
            ref="thresholdDialog"
            :isAdmin="is_admin"
            :isMobile="is_mobile"
            :device.sync="device"
            @update="onUpdated"
        />
        <Uploader
            id="imageUploader"
            ref="uploaderDialog"
            :device_guid="device_guid"
            :storage.sync="storage"
            @hide="hideModal"
        />
        <Incline 
            ref="srdsInclineDialog"
            :isAdmin="is_admin"
            :isMobile="is_mobile"
            @update="onUpdated"
        />
    </div>
</template>

<script>
import Device from '@/views/srds/device/Device'
import SWFMThreshold from '@/views/swfm/device/SWFMThreshold'
import Incline from '@/views/swfm/device/Incline'
import utils from '@/services/utils';

export default {
    name: 'SWFMDevice',
    extends: Device,
    components: {
        SWFMThreshold,
        Incline
    },
    data() {
        return {
          sensor_weight: 0.0,
          image_level: 0,
          image_label: '정상'
        }
      },
    watch: {
        device: function(new_val, old_val) {
            if(!_.isUndefined(new_val.sensor)){
                this.sensor_weight = this.calcSensorWeight();
                if(_.isUndefined(new_val.images[0].predict))
                    this.image_level = 0;
                else
                    this.image_level = this.parseImagelevel(new_val.images[0].predict);
            }
        }
    },   
    methods: {
        dangerDetectionData() {
            if(_.isUndefined(this.device.sensor))
                return;

            let thresholds = !_.isUndefined(_.find(this.device.threshold, {sensor_type : 32})) ? this.device.threshold : utils.getThresholds();

            const level0 = _.find(thresholds, {sensor_type : 32, level : 0 }).min; //0  -- 최소값 정상
            const level2 = _.find(thresholds, {sensor_type : 32, level : 2 }).min; //3  -- 최소값 주의
            const level3 = _.find(thresholds, {sensor_type : 32, level : 3 }).min; //5  -- 최소값 위험
            
            let checkSensor = this.sensor_weight;
            let checkImage = this.image_level;   

            let sensorColor = "#198754";
            let sensor_text = "정상";
            let imageColor = "#198754";
            let image_text = "정상";

            switch(checkImage){
                case 3:
                    imageColor = "red";
                    image_text = this.image_label;
                    this.sensorIconClass ="fa fa-fire";
                    this.sensorIconColor = "#e65100";
                    this.dangerDetectionImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 512 512"><path fill="#e65100" d="M159.3 5.4c7.8-7.3 19.9-7.2 27.7 .1c27.6 25.9 53.5 53.8 77.7 84c11-14.4 23.5-30.1 37-42.9c7.9-7.4 20.1-7.4 28 .1c34.6 33 63.9 76.6 84.5 118c20.3 40.8 33.8 82.5 33.8 111.9C448 404.2 348.2 512 224 512C98.4 512 0 404.1 0 276.5c0-38.4 17.8-85.3 45.4-131.7C73.3 97.7 112.7 48.6 159.3 5.4zM225.7 416c25.3 0 47.7-7 68.8-21c42.1-29.4 53.4-88.2 28.1-134.4c-4.5-9-16-9.6-22.5-2l-25.2 29.3c-6.6 7.6-18.5 7.4-24.7-.5c-16.5-21-46-58.5-62.8-79.8c-6.3-8-18.3-8.1-24.7-.1c-33.8 42.5-50.8 69.3-50.8 99.4C112 375.4 162.6 416 225.7 416z"/></svg>';
                    break;
                case 2:
                    imageColor = "#fd7e14";
                    image_text = this.image_label;
                    this.sensorIconClass = "fa fa-warning";
                    this.sensorIconColor = "#fd7e14";
                    this.dangerDetectionImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 512 512"><path fill="#ffa500" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>';
                    break;
                case 0:
                default:
                    image_text = this.image_label;
                    this.sensorIconClass = "fa fa-check-circle";
                    this.sensorIconColor = "#198754";
                    this.dangerDetectionImg = '<svg xmlns="http://www.w3.org/2000/svg" height="60" width="90" viewBox="0 0 512 512"><path fill="#198754" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>';                                            
                    break;
            }
            if(checkSensor > level3 ) {
                sensorColor = "red";
                sensor_text = "위험";
            } else if(checkSensor > level2 ) {
                sensorColor = "#fd7e14";
                sensor_text = "주의";
            } else{
                sensor_text = "정상";
            } 


            let result = "센서 분석" + "<span style='font-weight: bold; color: " + sensorColor + "'> ( " + sensor_text + " )</span><br>" + 
                         "사진 분석" + "<span style='font-weight: bold; color: " + imageColor + "'> ( " + image_text + " )";
            
            return result;
        },        
        parseImagelevel(values){
            var predict_resulte = values;
            var level_ = 0;
            var keys = Object.keys(predict_resulte);
            
            var max_predict_label="";
            var max_predict=0;

            for (var i=0; i< keys.length; i++) {
                var key = keys[i];
                if(max_predict < predict_resulte[key]){
                    max_predict_label = key;
                    max_predict = predict_resulte[key];
                }
                switch(key){
                    case 'Fire':
                    case 'Black smoke':
                    case 'Gray smoke':
                    case 'White smoke':
                        if(predict_resulte[key] > 0.3) level_ = 3;
                        else if(predict_resulte[key] <= 0.3) level_ = 2;
                        break;
                    default:
                        level_ = 0;
                        break;
                }
                //console.log("key : " + key + ", value : " + predict_resulte[key])
            }
            //console.log("감지 요소 : " + max_predict_label + "(" + parseFloat(max_predict*100).toFixed(1) + ")");
            switch(max_predict_label){
                case 'Fire':
                    max_predict_label = '불꽃';
                break;
                case 'Black smoke':
                case 'Gray smoke':
                case 'White smoke':
                    max_predict_label = '연기';
                break;
                case 'Cloud':
                    max_predict_label = '구름';
                break;
                case 'Fog':
                    max_predict_label = '안개';
                break;
                default:
                    max_predict_label = '비화재';
                break;
            }
            this.image_label = max_predict_label + "(" + parseFloat(max_predict*100).toFixed(1) + "%)";

            return level_;
        },
        calcSensorWeight() {
            var device_info = this.device;
            var sensor_value = {};
            var standard_value = {};
            
            standard_value.temp = device_info.standard.temp;
            standard_value.humi = device_info.standard.humi;
            standard_value.co2 = device_info.standard.co2;
            
            for (var i = 0; i < _.size(device_info.sensor.data); i++) {
                var s = device_info.sensor.data[i];
                if(s.type == 6){
                    if(s.id == 1)
                    sensor_value.temp = s.value;
                    else if(s.id == 2)
                    sensor_value.humi = s.value;
                }
                else if(s.type == 16)
                sensor_value.co2 = s.value;
            }

            var uk = ((sensor_value.temp / standard_value.temp) + (sensor_value.humi / standard_value.humi) + (sensor_value.co2 / standard_value.co2))/3;
            //console.log("비례상수: " + uk);

            var unit_const_t = uk / device_info.standard.t_threshold;
            var unit_const_h = uk / device_info.standard.h_threshold;
            var unit_const_c = uk / device_info.standard.c_threshold;
            var comp_weight = (unit_const_t + unit_const_h + unit_const_c) / 3;
            //console.log("복합 가중치: " + comp_weight);

            var temp_weight = (sensor_value.temp - standard_value.temp) * unit_const_t * comp_weight;//(g1[0]/100).toFixed(2);
            var humi_weight = (sensor_value.humi - standard_value.humi) * unit_const_h * comp_weight;//(g2[0]/100).toFixed(2);
            var co2_weight = (sensor_value.co2 - standard_value.co2) * unit_const_c * comp_weight;//(g3[0]/100).toFixed(2);
            var total_weight = (temp_weight + humi_weight + co2_weight).toFixed(2);

            //console.log("온도: " + temp_weight + " / 습도: " + humi_weight + " / CO2: " + co2_weight + " / 가중치: " + total_weight);
            console.log("가중치: " + total_weight);
            if(total_weight < 0) total_weight = 0; 
        
            return total_weight; 
        },  
    }
}
</script>

<style>

</style>